<template>
  <div>
    <div class="subheader py-2 py-lg-6 subheader-transparent">
      <div
        class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap p-0"
      >
        <!--begin::Page Title-->
        <div class="d-flex flex-column text-left">
          <h5 class="text-dark font-weight-bold my-1 mr-5">
            {{
              this.$route.query.type == "single"
                ? this.$t("admin.form.update_department")
                : this.$t("admin.form.multi_update_department") +
                  `(${this.$route.params.departments.length})`
            }}
          </h5>
          <span v-if="this.$route.query.type == 'multiple'">
            {{ $t("admin.popup.update.info") }}
          </span>
        </div>
        <!--end::Page Title-->
        <b-button
          @click="$router.push({ name: 'DepartmentsList' })"
          size="sm"
          pill
          variant="secondary"
          class="font-weight-bolder mr-2 text-uppercase"
        >
          <div class="d-flex">
            <i class="fa fa-chevron-left" />
            <span style=" white-space: nowrap">
              {{ $t("generic.buttons.back") }}
            </span>
          </div>
        </b-button>
      </div>
    </div>
    <basic-form
      :form="form"
      :fields="fields"
      :save="save"
      :error="error"
      :errors="errors"
      :isSubmitting="isSubmitting"
      v-if="loaded"
    />
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { mapActions } from "vuex";

import {
  getAdminStakeholder,
  updateStakeholder,
  getDepartments
} from "@/api/admin/stakeholders.api";
import BasicForm from "@/components/form/BasicForm.component";
import { validText } from "@/utils/validators";
import { getError } from "@/utils/helpers";

export default {
  name: "DepartmentUpdate",
  components: {
    BasicForm
  },
  data() {
    return {
      department: {},
      loaded: false,
      errors: [],
      error: false,
      form: {},
      departments: [],
      isSubmitting: false,
      correct: []
    };
  },
  methods: {
    ...mapActions("Admin", ["loadDepartments", "reset"]),
    callGetError(code, params) {
      return getError(code, params);
    },
    save(data, checked = []) {
      this.isSubmitting = true;
      const managedCompanyId = localStorage.getItem("managedCompanyId");

      if (this.$route.query.type == "single") {
        const formattedForm = {
          name: data.name,
          stakeholderCompanyId: managedCompanyId,
          stakeholderDepartmentParentId: data.departmentParent?.id || -1
        };

        if (!this.errors.length) {
          updateStakeholder("departments", this.$route.params.id, formattedForm)
            .then(response => {
              this.$root.$bvToast.toast(
                this.$t("admin.form.success.update_department_body", {
                  department: response.data.records.name
                }),
                {
                  title: this.$t("admin.form.success.update_department_header"),
                  variant: "success",
                  solid: true,
                  toaster: "b-toaster-top-center"
                }
              );
              this.loadDepartments();
              this.$router.push({
                name: "DepartmentsList"
              });
              this.isSubmitting = false;
            })
            .catch(error => {
              this.errors.push({
                name: error.response.data.records.userMessage,
                code: error.response.data.records.applicationCode,
                entity: "department"
              });
              this.$root.$bvToast.toast(
                this.callGetError(error.response.data.records.applicationCode),
                {
                  title: this.callGetError("update_department", {
                    department: this.form.name
                  }),
                  variant: "danger",
                  solid: true,
                  toaster: "b-toaster-top-center"
                }
              );
              this.isSubmitting = false;
            });
        }
      } else {
        const formattedForm = {};

        if (checked["departmentParent"] && data.departmentParent) {
          formattedForm.stakeholderDepartmentParentId =
            data.departmentParent.id;
        }

        if (!this.errors.length) {
          const toObject = Object.fromEntries(Object.entries(checked));
          const areChecked = Object.values(toObject).filter(
            element => element == true
          ).length;

          if (areChecked == 0) {
            this.$swal({
              html: this.$t("admin.popup.update.no_fields_checked"),
              icon: "warning",
              confirmButtonColor: "#0BB783",
              confirmButtonText: this.$t("admin.popup.aceptButton")
            });
          } else {
            this.$swal({
              html: this.$t("admin.popup.update.multi_department", {
                fields: areChecked,
                departments: this.$route.params.departments.length,
                confirmation: this.$t("admin.popup.update.update_confirmation")
              }),
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#0BB783",
              confirmButtonText: this.$t("admin.popup.confirmButton"),
              cancelButtonText: this.$t("admin.popup.cancelButton"),
              input: "text",
              inputValidator: value => {
                if (
                  value === this.$t("admin.popup.update.update_confirmation")
                ) {
                  var limit = 50;
                  var departmentIds = this.$route.params.departments;

                  const sendRequestsInBatches = (ids, startIndex) => {
                    const batchIds = _.slice(
                      ids,
                      startIndex,
                      startIndex + limit
                    );
                    if (batchIds.length === 0) {
                      this.isSubmitting = false;
                      this.$root.$bvToast.toast(
                        this.$t("admin.form.success.update_departments_body"),
                        {
                          title: this.$t(
                            "admin.form.success.update_departments_headers",
                            {
                              departments: this.correct.length
                            }
                          ),
                          variant: "success",
                          solid: true,
                          toaster: "b-toaster-top-center"
                        }
                      );
                      this.loadDepartments();
                      this.$router.push({
                        name: "DepartmentsList"
                      });
                      return;
                    }

                    let requests = [];

                    _.each(batchIds, id => {
                      let paramsToSend = _.clone(formattedForm);
                      paramsToSend.stakeholdersId = [id];
                      requests.push(
                        updateStakeholder("departments", id, paramsToSend)
                      );
                    });

                    let incorrect = [];

                    axios
                      .all(requests)
                      .then(
                        axios.spread((...responses) => {
                          _.each(responses, response => {
                            let data = JSON.parse(response.config.data);
                            if (response.status === 200) {
                              this.correct.push(...data.stakeholdersId);
                            } else {
                              incorrect.push(...data.stakeholdersId);
                            }
                          });

                          sendRequestsInBatches(ids, startIndex + limit);
                        })
                      )
                      .catch(error => {
                        this.errors.push({
                          name: error.response.data.records.userMessage,
                          code: error.response.data.records.applicationCode,
                          entity: "user"
                        });
                        this.$root.$bvToast.toast(
                          this.callGetError(
                            error.response.data.records.applicationCode
                          ),
                          {
                            title: this.callGetError("update_department"),
                            variant: "danger",
                            solid: true,
                            toaster: "b-toaster-top-center"
                          }
                        );

                        sendRequestsInBatches(ids, startIndex + limit);
                      });
                  };
                  sendRequestsInBatches(departmentIds, 0);
                } else {
                  this.isSubmitting = false;
                  return this.$t("admin.popup.incorrectWord");
                }
              }
            });
          }
        }
      }
    }
  },
  computed: {
    fields() {
      let single = [
        {
          id: "name",
          required: true,
          type: "text",
          validation: validText(this.form.name),
          maxLength: 255
        }
      ];

      let multi = [
        {
          id: "departmentParent",
          type: "select",
          required: false,
          label: "label",
          options: this.departmentOptions
        }
      ];

      let fields =
        this.$route.query.type == "single" ? single.concat(multi) : multi;

      if (this.$route.query.type == "multiple") {
        _.each(fields, field => {
          field.multiple = true;
        });
      }

      return fields;
    },
    departmentOptions() {
      let departments = [];
      _.each(this.departments, department => {
        if (department.stakeholderId !== this.department.stakeholderId) {
          departments.push({
            id: department.stakeholderId,
            label: department.name
          });
        }
      });
      return departments;
    }
  },
  mounted() {
    let requests = [getDepartments(localStorage.getItem("managedCompanyId"))];

    if (this.$route.query.type == "single") {
      requests.push(
        getAdminStakeholder("departments", this.$route.params.id, {
          stakeholderDepartmentParentId: true,
          stakeholderDepartmentId: true,
          stakeholderDepartmentParentName: true
        })
      );
    }
    axios.all(requests).then(
      axios.spread((departmentsResponse, departmentResponse) => {
        if (this.$route.query.type == "single") {
          this.departments = departmentsResponse.data.records;
        } else {
          this.departments = _.filter(
            departmentsResponse.data.records,
            department =>
              !_.includes(
                this.$route.params.departments,
                department.stakeholderId
              )
          );
        }

        if (departmentResponse) {
          this.department = departmentResponse.data.records;
          this.form = {
            name: this.department.name,
            departmentParent: _.find(
              this.departmentOptions,
              el => el.id == this.department.stakeholderDepartmentParentId
            )
          };
        } else {
          this.form = {
            departmentParent: ""
          };
        }

        this.loaded = true;
      })
    );
  }
};
</script>
